import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import Image from "next/image";
import Link from "next/link";
import { Button } from "components/atoms/Button";
import { ButtonType } from "components/atoms/Button/types";
import { Notification } from "components/atoms/Icon";
import PopoverWithChildren from "components/atoms/Popover";
import { PopoverPanelVariant } from "components/atoms/Popover/types";
import { getUnreadNotifications, markAllNotificationsAsRead, markNotificationAsRead } from "lib/actions";
import { INotification } from "types";
import * as Styled from "./Notifications.styled";
const Notifications: React.FC = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [isClient, setIsClient] = useState(false); // State to track if client-side

  useEffect(() => {
    setIsClient(true); // Set to true after initial render
  }, []);
  useEffect(() => {
    async function fetchNotifications() {
      const response = await getUnreadNotifications();
      if (response.notifications) {
        setNotifications(response.notifications);
      }
    }
    fetchNotifications();
  }, []);
  const handleNotificationClick = async (notification: INotification, event: React.MouseEvent) => {
    if (event.type === "click" || event.button === 1) {
      const {
        id
      } = notification;

      // Mark the notification as read
      const response = await markNotificationAsRead(id);
      if (!response.error) {
        setNotifications(notifications.filter(notif => notif.id !== id));
      }
    }
  };
  const getNotificationHref = (notification: INotification) => {
    const {
      relatedEntityId,
      type
    } = notification;
    if (!relatedEntityId) return "#";
    if (["COMMENT", "IMAGE", "REPLY", "LIKE"].includes(type)) {
      return `/images/${relatedEntityId}`;
    } else if (type === "PRIZE") {
      return `/competitions/${relatedEntityId}`;
    }
    return "#";
  };
  const markAllAsRead = async () => {
    const response = await markAllNotificationsAsRead();
    if (!response.error) {
      setNotifications([]);
    }
  };
  const formatDate = (dateString: string) => {
    const date = DateTime.fromISO(dateString, {
      zone: "utc"
    });
    const now = DateTime.now().setZone("utc");
    const diffInSeconds = now.diff(date, "seconds").seconds;
    if (diffInSeconds < 60) {
      return `${Math.floor(diffInSeconds)} seconds ago`;
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    } else if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    } else {
      return date.toFormat("MMM dd, yyyy");
    }
  };
  if (!isClient) {
    return <Styled.IconButton>
                <Image priority src={Notification} alt="Notifications" />
                {notifications.length > 0 && <Styled.NotificationCount>
                        {notifications.length}
                    </Styled.NotificationCount>}
            </Styled.IconButton>;
  }
  return <PopoverWithChildren buttonNode={<Styled.IconButton>
                    <Image priority src={Notification} alt="Notifications" />
                    {notifications.length > 0 && <Styled.NotificationCount>
                            {notifications.length}
                        </Styled.NotificationCount>}
                </Styled.IconButton>} position="bottomLeft" panelVariant={PopoverPanelVariant.NOTIFICATIONS} data-sentry-element="PopoverWithChildren" data-sentry-component="Notifications" data-sentry-source-file="index.tsx">
            <Button variant={ButtonType.PRIMARY} onClick={markAllAsRead} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                Mark All as Read
            </Button>
            {notifications.length === 0 ? <Styled.NoNotifications>
                    No new notifications
                </Styled.NoNotifications> : notifications.map(notification => <Link href={getNotificationHref(notification)} key={notification.id} passHref>
                        <Styled.NotificationItem as="a" onClick={event => handleNotificationClick(notification, event)} onAuxClick={event => handleNotificationClick(notification, event)}>
                            <Styled.NotificationMessage>
                                <Styled.Text>
                                    {notification.message}
                                </Styled.Text>
                            </Styled.NotificationMessage>
                            <Styled.NotificationTimestamp suppressHydrationWarning>
                                {formatDate(notification.createdAt)}
                            </Styled.NotificationTimestamp>
                        </Styled.NotificationItem>
                    </Link>)}
        </PopoverWithChildren>;
};
export default Notifications;