import { Auth } from "@aws-amplify/auth";
import { AppProps } from "next/app";
import Head from "next/head";
import { GoogleAnalytics } from "nextjs-google-analytics";
import LayoutWrapper from "components/organisms/LayoutWrapper";
import { GlobalStyles } from "utils/styling/globalStyles.styled";
Auth.configure({
  region: "eu-west-2",
  userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_WEB_CLIENT,
  oauth: {
    domain: process.env.NEXT_PUBLIC_COGNITO_AUTH_DOMAIN,
    scope: ["email", "openid", "profile"],
    redirectSignIn: process.env.NEXT_PUBLIC_COGNITO_REDIRECT,
    redirectSignOut: process.env.NEXT_PUBLIC_COGNITO_REDIRECT,
    responseType: "code"
  }
});
function MyApp({
  Component,
  pageProps
}: AppProps) {
  return <>
            <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
                <meta name="description" content="DnD Character Creator. AI Generated Images for your D&D Characters within seconds. CharGen is designed to create AI Generated D&D Character Art. Create stunning digital art of your RPG characters for free!" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
                <title>CharGen: AI Generated D&D Character Art</title>
                <meta property="og:url" key="og:url" content="https://char-gen.com" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
                <meta property="og:description" key="og:description" content="DnD Character Creator. AI Generated Images for your D&D Characters within seconds. CharGen is designed to create AI Generated D&D Character Art. Create stunning digital art of your RPG characters for free!" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
                <meta property="og:title" key="og:title" content="CharGen: AI Generated D&D Character Art | Best Fantasy AI Art and DnD Character Creator" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
                <meta property="og:image" key="og:image" content="https://chargen-fe.s3.eu-west-2.amazonaws.com/android-chrome-512x512.png" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
            </Head>
            <GlobalStyles data-sentry-element="GlobalStyles" data-sentry-source-file="_app.tsx" />
            <GoogleAnalytics trackPageViews gaMeasurementId="G-5R8RL76MN1" data-sentry-element="GoogleAnalytics" data-sentry-source-file="_app.tsx" />
            <GoogleAnalytics trackPageViews gaMeasurementId="AW-16678352049" data-sentry-element="GoogleAnalytics" data-sentry-source-file="_app.tsx" />
            <div id="root" style={{
      backgroundColor: "#1f2136"
    }}>
                <LayoutWrapper data-sentry-element="LayoutWrapper" data-sentry-source-file="_app.tsx">
                    <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                </LayoutWrapper>
            </div>
        </>;
}
export default MyApp;