import { ButtonHTMLAttributes, FC, ReactNode } from "react";
import { ButtonType } from "components/atoms/Button/types";
import * as Styled from "./Button.styled";
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  disabled?: boolean;
  variant?: ButtonType;
}
export const Button: FC<ButtonProps> = ({
  children,
  disabled = false,
  variant = ButtonType.PRIMARY,
  ...props
}) => {
  return <Styled.Button {...props} $variant={variant} aria-disabled={disabled} disabled={disabled} data-sentry-element="unknown" data-sentry-component="Button" data-sentry-source-file="index.tsx">
            {children}
        </Styled.Button>;
};
export const InlineButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  return <Styled.InlineButton {...props} data-sentry-element="unknown" data-sentry-component="InlineButton" data-sentry-source-file="index.tsx">{children}</Styled.InlineButton>;
};