/* eslint-disable indent */
import { createGlobalStyle } from "styled-components";

import { colourScheme } from "./colours";

const brushSize = 30;
const cursorSVG = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${brushSize}" height="${brushSize}">
        <circle cx="${brushSize / 2}" cy="${brushSize / 2}" r="${
            brushSize / 2
        }" fill="none" stroke="black" stroke-width="1"/>
    </svg>`;
const cursorURL = `data:image/svg+xml;base64,${btoa(cursorSVG)}`;

export const GlobalStyles = createGlobalStyle`
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Nunito", sans-serif;
    overflow-x: hidden !important;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

/* Custom scrollbar styles */
    &::-webkit-scrollbar {
        width: 0.8rem;
    }

    &::-webkit-scrollbar-track {
        background: ${colourScheme.primary};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${colourScheme.secondary};
        border-radius: 10px;
        border: 3px solid ${colourScheme.primaryDark};
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: ${colourScheme.secondaryDark};
    }

    /* Firefox scrollbar styles */
    scrollbar-width: thin;
    scrollbar-color: ${colourScheme.secondaryDark} ${colourScheme.primaryLight};

html {
    text-rendering: optimizeLegibility;
}

html,
body {
    background-color: ${colourScheme.primaryDark};
    color: ${colourScheme.white};
}

#root {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: ${colourScheme.primary}
}

a {
    text-decoration: none;
    color: inherit;
}

#sentry-feedback {
    --background: ${colourScheme.primaryDark} !important;
    --background-hover: ${colourScheme.primary} !important;
    --foreground: ${colourScheme.white} !important;
    --error: ${colourScheme.error} !important;
    --success: ${colourScheme.success} !important;
    --border-radius: 0.5rem !important;
    --accent-background: ${colourScheme.secondaryDark} !important;
    --accent-background-hover: ${colourScheme.secondary} !important;
    --font-family: "Nunito", sans-serif !important;
    --font-size: 1rem !important;
    --form-border-radius: 0.5rem !important;
    --position: fixed !important;
    --bottom: 0.5rem !important;
    --left: auto !important;
    --right: 0.5rem !important;
    --top: auto !important;
    --z-index: 999 !important;
}

div[id$="Tip"] {
    color: ${colourScheme.white};
    background: ${colourScheme.primaryDark};
    --rt-opacity: 0.99;
}

pre[class*="language-"],
code[class*="language-"] {
    color: #d6deeb;
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none;
}

pre[class*="language-"] {
    padding: 1em;
    overflow: auto;
    background-color: #1a202c; /* Tailwind class: bg-gray-900 */
    border: 1px solid #4a5568; /* Tailwind class: border-gray-700 */
}

code[class*="language-"] {
    background-color: #1a202c; /* Tailwind class: bg-gray-900 */
    border: 1px solid #4a5568; /* Tailwind class: border-gray-700 */
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
}

.token.comment,
.token.prolog,
.token.cdata {
    color: rgb(99, 119, 119);
    font-style: italic;
}

.token.punctuation {
    color: rgb(199, 146, 234);
}

.namespace {
    color: rgb(178, 204, 214);
}

.token.deleted {
    color: rgba(239, 83, 80, 0.56);
    font-style: italic;
}

.token.symbol,
.token.property {
    color: rgb(128, 203, 196);
}

.token.tag,
.token.operator,
.token.keyword {
    color: rgb(127, 219, 202);
}

.token.boolean {
    color: rgb(255, 88, 116);
}

.token.number {
    color: rgb(247, 140, 108);
}

.token.constant,
.token.function,
.token.builtin,
.token.char {
    color: rgb(130, 170, 255);
}

.token.selector,
.token.doctype {
    color: rgb(199, 146, 234);
    font-style: italic;
}

.token.attr-name,
.token.inserted {
    color: rgb(173, 219, 103);
    font-style: italic;
}

.token.string,
.token.url,
.token.entity,
.language-css .token.string,
.style .token.string {
    color: rgb(173, 219, 103);
}

.token.class-name,
.token.atrule,
.token.attr-value {
    color: rgb(255, 203, 139);
}

.token.regex,
.token.important,
.token.variable {
    color: rgb(214, 222, 235);
}

.token.important,
.token.bold {
    font-weight: bold;
};
.upper-canvas {
    cursor: url('${cursorURL}') ${brushSize / 2} ${
        brushSize / 2
    }, auto !important;
  }
`;
