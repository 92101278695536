import Image from "next/image";
import styled from "styled-components";

import { colourScheme } from "utils/styling/colours";

// Styled components for the footer
export const FooterContainer = styled.footer`
    clear: both;
    background-color: ${colourScheme.primaryDark};
    color: white;
    padding: 1.25rem 2rem;
    text-align: center;
    bottom: 0;
    position: relative;
    width: 100%;
    height: auto;
`;

export const FooterLinks = styled.div`
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem;

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const FooterLink = styled.a`
    color: white;
    margin: 0 1rem;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export const SocialMediaLinks = styled.div`
    margin-bottom: 0.5rem;
`;

export const Icon = styled(Image)`
    width: 2rem;
    height: 2rem;
`;

export const TradingName = styled.div`
    padding-top: 0.4rem;
    font-size: 0.5rem;
`;
