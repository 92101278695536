import { ToastContainer } from "react-toastify";
import styled from "styled-components";

import { colourScheme } from "utils/styling/colours";

export const StyledToastContainer = styled(ToastContainer).attrs({
    className: "toast-container",
    toastClassName: "toast",
    bodyClassName: "body",
    progressClassName: "progress",
})`
    width: fit-content%;

    .toast {
        background-color: ${colourScheme.primaryDark};
        color: ${colourScheme.white};
        font-family: "Nunito", sans-serif;
    }

    .body {
        color: ${colourScheme.white};
    }

    .progress {
        background-color: ${colourScheme.success};
    }

    button[aria-label="close"] {
        color: ${colourScheme.white};
    }
`;
