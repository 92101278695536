import { styled } from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";
import { Small } from "utils/styling/typography.styled";

export const Header = styled.header`
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 1rem;
    & span {
        font-size: 0.75rem;
    }

    & h1 {
        font-size: 1.5rem;
    }
    ${device.lg} {
        padding: 0.5rem 2rem;
    }
    ${device.xl} {
        & span {
            font-size: 1rem;
        }
        & h1 {
            font-size: 2.5rem;
        }
        padding: 0.75rem 5rem;
    }
    width: 100%;
    hr {
        align-self: stretch;
    }

    background-color: ${colourScheme.primaryDark};
    z-index: 1000;
`;

export const AuthContainer = styled.div`
    display: none;
    ${device.lg} {
        display: flex;
    }
    gap: 1rem;
    align-items: center;
`;

export const NotificationContainer = styled.div`
    display: flex;
    ${device.lg} {
        display: none;
    }
    gap: 1rem;
    align-items: center;
    margin: 0 1rem;
`;

export const PremiumLevelIndicator = styled(Small)`
    vertical-align: top;
    font-size: 0.75rem !important;
    color: ${colourScheme.secondary};
`;

export const ShowMenu = styled.div`
    display: flex;
    position: relative;
    padding: 0.5rem 1rem;
    gap: 1rem;
    & > a {
        color: ${colourScheme.fullWhite};
        padding: 0.75rem 1rem;
        text-decoration: none;
        display: flex;
        width: 100%;
        background: none;
        border: none;
        cursor: pointer;

        &:hover {
            color: ${colourScheme.secondary};
        }
    }
`;
