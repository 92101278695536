import React, { useContext, useEffect, useState } from "react";
import { ButtonType } from "components/atoms/Button/types";
import { ChangelogModal } from "components/molecules/ChangelogModal";
import { getUnseenChangelogs, markChangelogSeen } from "lib/actions";
import { AppContext } from "lib/contexts";
import { AppReducerActionType, UserChangelogViewDTO } from "types";
import { BannerContainer, BannerText, Button } from "./ChangelogBanner.styled";
const ChangelogBanner: React.FC = () => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  const [unseenChangelogs, setUnseenChangelogs] = useState<UserChangelogViewDTO[]>([]);
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  useEffect(() => {
    const fetchUnseenChangelogs = async () => {
      try {
        const response = await getUnseenChangelogs();
        if (response.unseenChangelogs) {
          setUnseenChangelogs(response.unseenChangelogs);
          setShowBanner(true);
        }
      } catch (error) {
        console.error("Error fetching unseen changelogs", error);
      }
    };
    fetchUnseenChangelogs();
  }, []);
  const handleCloseBanner = () => {
    if (unseenChangelogs.length > 0) {
      const latestChangelogId = unseenChangelogs[0].changelogId;
      markChangelogSeen(latestChangelogId);
    }
    setShowBanner(false);
  };
  const handleViewChangelog = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    handleCloseBanner();
    dispatch({
      type: AppReducerActionType.TOGGLE_OVERLAY_VISIBILITY,
      payload: {
        ...state,
        isOverlayVisible: false
      }
    });
  };
  if (!showBanner) return null;
  return <>
            <BannerContainer data-sentry-element="BannerContainer" data-sentry-source-file="index.tsx">
                <BannerText data-sentry-element="BannerText" data-sentry-source-file="index.tsx">✨ See the Latest CharGen Updates! ✨</BannerText>
                <Button variant={ButtonType.SECONDARY} onClick={handleViewChangelog} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                    View
                </Button>
                <Button variant={ButtonType.PRIMARY} onClick={handleCloseBanner} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                    X
                </Button>
            </BannerContainer>
            <ChangelogModal isOpen={showModal} changelogs={unseenChangelogs} onClose={handleCloseModal} data-sentry-element="ChangelogModal" data-sentry-source-file="index.tsx" />
        </>;
};
export default ChangelogBanner;