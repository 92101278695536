import { styled } from "styled-components";

import { device } from "utils/sizes";

export const PaymentsContainer = styled.div`
    overscroll-behavior: contain;
    overflow-y: auto;
    height: 90vh;
    ${device.md} {
        height: 75vh;
    }
    width: 90vw;
    ${device.lg} {
        width: 75vw;
    }
    ${device.xl} {
        width: 66.6vw;
    }
`;
