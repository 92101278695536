import Image from "next/image";
import styled from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";
import { Text } from "utils/styling/typography.styled";

export const ModalContainer = styled.div`
    border-radius: 0.5rem;
    padding: 2rem;
    width: 100%;
    max-width: 100vw;
    max-height: 100vh;
    overflow-y: auto;

    ${device.md} {
        max-width: 60vw;
        max-height: 80vh;
    }

    ${device.lg} {
        max-width: 50vw;
        max-height: 80vh;
    }

    ${device.xl} {
        max-width: 30vw;
        max-height: 80vh;
    }
`;

export const ChangelogList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

export const ChangelogItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-bottom: 1px solid ${colourScheme.white};
    padding-bottom: 1rem;

    &:last-child {
        border-bottom: none;
    }
`;

export const ChangelogImage = styled(Image)`
    border-radius: 0.5rem;
`;

export const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    padding: 1rem;
    justify-content: center;
    background-color: ${colourScheme.primaryLight};
`;

export const ChangelogDescription = styled(Text)`
    ul {
        padding-left: 1.5rem;
        list-style-type: disc;
    }

    ol {
        padding-left: 1.5rem;
        list-style-type: decimal;
    }

    li {
        margin-bottom: 0.5rem;
    }

    a {
        color: ${colourScheme.primary};
        text-decoration: underline;
    }

    p {
        margin-bottom: 1rem;
    }

    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }
`;
