import { forwardRef, InputHTMLAttributes, useState } from "react";
import * as Styled from "./Input.styled";
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  password?: boolean;
  multiline?: boolean;
}
export const Input = forwardRef<HTMLInputElement, InputProps>(({
  password = false,
  multiline = false,
  type,
  ...props
}, ref) => {
  const [isPassword, setIsPassword] = useState<boolean>(password || false);
  return <Styled.InputWrapper>
                <Styled.InputContainer>
                    {multiline ? <Styled.TextArea ref={ref as React.RefObject<HTMLTextAreaElement>} {...props as any} /> : <Styled.Input ref={ref} type={password ? isPassword ? "password" : "text" : type} {...props} />}
                    {password && <Styled.PasswordToggle onClick={() => setIsPassword(!isPassword)}>
                            {isPassword ? "show" : "hide"}
                        </Styled.PasswordToggle>}
                </Styled.InputContainer>
                <Styled.ErrorMessage>
                    {props["aria-errormessage"]}
                </Styled.ErrorMessage>
            </Styled.InputWrapper>;
});
Input.displayName = "Input";