import { FC, useContext } from "react";
import { ButtonType } from "components/atoms/Button/types";
import { GemCounter } from "components/atoms/GemCounter";
import { AppContext } from "lib/contexts";
import { AppReducerActionType, UserPremiumType } from "types";
import { Span } from "utils/styling/typography.styled";
import { STRIPE_CUSTOMER_PORTAL } from "utils/urlConstants";
import * as Styled from "./GemCounterHeader.styled";
type HeaderProps = {
  userPremiumLevel?: UserPremiumType;
  userEmail?: string;
};
export const GemCounterHeader: FC<HeaderProps> = ({
  userPremiumLevel,
  userEmail
}) => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  const handlePricingClick = () => {
    dispatch({
      type: AppReducerActionType.SHOW_PRICING_TABLE,
      payload: {
        ...state,
        showPricingTable: !state.showPricingTable
      }
    });
  };
  const handleSubscribeClick = () => {
    dispatch({
      type: AppReducerActionType.SHOW_SUBSCRIPTION_TABLE,
      payload: {
        ...state,
        showSubscriptionTable: !state.showSubscriptionTable
      }
    });
  };
  const handleMySubscriptionClick = () => {
    window.open(STRIPE_CUSTOMER_PORTAL + "?prefilled_email=" + userEmail, "_blank");
  };
  const isPremium = userPremiumLevel !== UserPremiumType.NONE;
  return <Styled.Container data-sentry-element="unknown" data-sentry-component="GemCounterHeader" data-sentry-source-file="index.tsx">
            {!isPremium && <Styled.Button variant={ButtonType.SUCCESS} onClick={handleSubscribeClick}>
                    <Span>Subscribe</Span>
                </Styled.Button>}
            {isPremium && <Styled.Button variant={ButtonType.SUCCESS} onClick={handleMySubscriptionClick}>
                    <Span>My Subscription</Span>
                </Styled.Button>}
            <Styled.Button variant={ButtonType.SUCCESS} onClick={handlePricingClick} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Refill Gold</Span>
                <Styled.NewLabel data-sentry-element="unknown" data-sentry-source-file="index.tsx">*New*</Styled.NewLabel>
            </Styled.Button>
            <GemCounter gold={state.gold} data-sentry-element="GemCounter" data-sentry-source-file="index.tsx" />
        </Styled.Container>;
};