import { styled } from "styled-components";

import { colourScheme } from "utils/styling/colours";
import { Text } from "utils/styling/typography.styled";

export const AuthModalContainer = styled.div`
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    background-color: ${colourScheme.primaryLight};
    text-align: center;
    align-items: center;
    border-radius: 0.5rem;
    max-width: 26rem;
    & > hr {
        width: 100%;
        color: ${colourScheme.white};
    }
`;

export const Checkbox = styled.label`
    position: relative;
    display: block;
    cursor: pointer;
    user-select: none;
    font-size: 1.5rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        transition: all 0.25s;
        display: block;
        height: 1.5rem;
        width: 1.5rem;
        background-color: ${colourScheme.primaryDark};
        border-radius: 0.5rem;
    }

    &:hover input ~ .checkmark {
        border-radius: 0.25rem;
    }

    & input:checked ~ .checkmark {
        background-color: ${colourScheme.secondary};
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    .checkmark:after {
        left: 0.5rem;
        top: 0.3rem;
        width: 0.3rem;
        height: 0.6rem;
        border: solid ${colourScheme.white};
        border-width: 0 0.1875rem 0.1875rem 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

export const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const Label = styled.label`
    display: block;
    font-size: 0.75rem;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
`;

export const AuthForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
`;

export const TextButton = styled(Text)`
    opacity: 0.8;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: underline;
`;

export const InlineInput = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 2;
`;
