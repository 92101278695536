import styled from "styled-components";

import { Button as StyledButton } from "components/atoms/Button";
import { colourScheme } from "utils/styling/colours";

export const BannerContainer = styled.div`
    position: absolute;
    top: 5rem;
    width: 100%;
    background-color: ${colourScheme.successDark};
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 750;
`;

export const BannerText = styled.div`
    flex: 1;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
`;

export const Button = styled(StyledButton)`
    margin: 0 0.5rem;
`;
