import { styled } from "styled-components";

export const Title = styled.h1`
    width: 100%;
    font-size: 2.5rem;
    font-weight: 700;
    font-family: inherit;
`;

export const SubTitle = styled.h2`
    width: 100%;
    font-size: 1.75rem;
    font-weight 600;
    font-family: inherit;
`;

export const Text = styled.p`
    font-size: 1.25rem;
    font-family: inherit;
`;

export const Span = styled.span`
    font-family: inherit;
    font-size: 1rem;
    font-weight: 700;
`;

export const Small = styled.span`
    font-family: inherit;
    font-size: 0.75rem;
    font-weight: 700;
`;

export const Label = styled.label`
    font-family: inherit;
    font-size: 1.25rem;
    font-weight: 600;
`;
