import { styled } from "styled-components";

import { ButtonType } from "components/atoms/Button/types";
import { colourScheme } from "utils/styling/colours";

const getVariantColour = (variant?: ButtonType): [string, string] => {
    switch (variant) {
        case ButtonType.PRIMARY:
            return [colourScheme.primary, colourScheme.primaryLight];
        case ButtonType.SECONDARY:
            return [colourScheme.secondaryDark, colourScheme.secondary];
        case ButtonType.SUCCESS:
            return [colourScheme.successDark, colourScheme.success];
        case ButtonType.ALERT:
            return [colourScheme.alertDark, colourScheme.alert];
        case ButtonType.ERROR:
            return [colourScheme.errorDark, colourScheme.error];
        default:
            return [colourScheme.primary, colourScheme.primaryLight];
    }
};

export const Button = styled.button<{ $variant?: ButtonType }>`
    white-space: nowrap;
    transition: all 0.25s;
    padding: 0.5rem 1rem;
    font-family: inherit;
    width: auto;
    height: fit-content;
    border-radius: 0.5rem;
    background-color: ${(props) => getVariantColour(props.$variant)[0]};
    color: ${colourScheme.white};
    text-shadow: 0 0 2px ${colourScheme.primaryDark};
    border: 2px solid ${colourScheme.white};

    &:disabled {
        cursor: default;
        opacity: 0.8;
    }

    &:hover:enabled,
    &:focus:enabled {
        background-color: ${(props) => getVariantColour(props.$variant)[1]};
        border-radius: 0.25rem;
        cursor: pointer;
    }
`;

export const InlineButton = styled.span`
    width: fit-content;
    align-self: end;

    transition: opacity 0.25s;

    line-height: 2rem;

    cursor: pointer;

    &:hover:enabled,
    &:focus:enabled {
        opacity: 1;
        cursor: pointer;
    }

    &:disabled {
        cursor: default;
        opacity: 0.8;
    }
`;
