import { useContext, useEffect, useRef, useState } from "react";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";

import { AppReducerActionType } from "types";

import { AppContext } from "./contexts";

export const useDetectClickOut = (initState: boolean) => {
    const triggerRef = useRef<any>(null); // optional
    const nodeRef = useRef<any>(null); // required
    const pathname = usePathname();

    const [show, setShow] = useState(initState);
    const handleClickOutside = (event: any) => {
        if (triggerRef.current && triggerRef.current.contains(event.target)) {
            return setShow(!show);
        }

        if (nodeRef.current && !nodeRef.current.contains(event.target)) {
            return setShow(false);
        }
    };
    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    });

    useEffect(() => {
        const handleResize = () => {
            setShow(false);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    const handleChildButtonClick = () => {
        setShow(false);
    };

    useEffect(() => {
        if (nodeRef.current) {
            const childButton = nodeRef.current.querySelector("button");
            if (childButton) {
                childButton.addEventListener("click", handleChildButtonClick);
            }
        }
        return () => {
            if (nodeRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                const childButton = nodeRef.current.querySelector("button");
                if (childButton) {
                    childButton.removeEventListener(
                        "click",
                        handleChildButtonClick
                    );
                }
            }
        };
    }, []);

    useEffect(() => {
        setShow(false);
    }, [pathname]);

    return {
        triggerRef,
        nodeRef,
        show,
        setShow,
    };
};

export const useReferralCode = () => {
    const router = useRouter();
    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        const referralCode = router.query.referralCode as string;
        if (referralCode) {
            dispatch({
                type: AppReducerActionType.SET_REFERRAL_CODE,
                payload: {
                    ...state,
                    referralCode: referralCode,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query, dispatch]);
};
