import { FC, useCallback, useContext } from "react";
import { Auth } from "@aws-amplify/auth";
import * as Sentry from "@sentry/react";
import Link from "next/link";
import { Button } from "components/atoms/Button";
import { ButtonType } from "components/atoms/Button/types";
import PopoverWithChildren from "components/atoms/Popover";
import { AppContext } from "lib/contexts";
import { AppReducerActionType, UserPremiumType } from "types";
import { Span } from "utils/styling/typography.styled";
import { STRIPE_CUSTOMER_PORTAL } from "utils/urlConstants";
import * as Styled from "./Dropdown.styled";
type DropdownProps = {
  userEmail?: string;
  userPremiumLevel?: UserPremiumType;
};
export const Dropdown: FC<DropdownProps> = ({
  userEmail,
  userPremiumLevel
}) => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  const handlePricingClick = () => {
    dispatch({
      type: AppReducerActionType.SHOW_PRICING_TABLE,
      payload: {
        ...state,
        showPricingTable: !state.showPricingTable
      }
    });
  };
  const handleSubscribeClick = () => {
    dispatch({
      type: AppReducerActionType.SHOW_SUBSCRIPTION_TABLE,
      payload: {
        ...state,
        showSubscriptionTable: !state.showSubscriptionTable
      }
    });
  };
  const handleMySubscriptionClick = () => {
    window.open(STRIPE_CUSTOMER_PORTAL + "?prefilled_email=" + userEmail, "_blank");
  };
  const handleSignOut = useCallback(async () => {
    setTimeout(async () => {
      await Auth.signOut();
      Sentry.setUser(null);
      window.location.replace(window.location.origin);
    }, 500);
  }, []);
  const isPremium = userPremiumLevel !== UserPremiumType.NONE;
  return <PopoverWithChildren buttonNode={<Button variant={ButtonType.PRIMARY}>
                    <Span>{state.username}</Span>
                </Button>} position="bottom" data-sentry-element="PopoverWithChildren" data-sentry-component="Dropdown" data-sentry-source-file="index.tsx">
            {!isPremium && <Styled.PopoverItem onClick={handleSubscribeClick}>
                    <Span>Subscribe</Span>
                </Styled.PopoverItem>}
            {isPremium && <Styled.PopoverItem onClick={handleMySubscriptionClick}>
                    <Span>My Subscription</Span>
                </Styled.PopoverItem>}
            <Styled.PopoverItem onClick={handlePricingClick} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Refill Gold</Span>
                <Styled.NewLabel data-sentry-element="unknown" data-sentry-source-file="index.tsx">*New*</Styled.NewLabel>
            </Styled.PopoverItem>
            <Styled.PopoverItem data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Link href={"/profile"} data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Profile</Span>
                </Link>
            </Styled.PopoverItem>
            <Styled.PopoverItem onClick={handleSignOut} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Sign Out</Span>
            </Styled.PopoverItem>
        </PopoverWithChildren>;
};