import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { colourScheme } from "utils/styling/colours";

export const ReactTooltipStyled = styled(Tooltip).attrs({
    globalCloseEvents: {
        clickOutsideAnchor: true,
        escape: true,
        scroll: true,
    },
    closeEvents: {
        blur: true,
        click: true,
    },
    openEvents: {
        click: true,
        focus: true,
    },
    place: "top",
})`
    background-color: ${colourScheme.primaryDark} !important;
    width: max-content !important;
    height: max-content !important;
    max-width: 90vw !important;
    overflow: hidden !important;
    padding-left: 2rem !important;
    z-index: 1000 !important;
`;
