"use client";

import { useEffect, useReducer, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import { StyledToastContainer } from "components/atoms/Toast/Toast.styled";
import ChangelogBanner from "components/molecules/ChangelogBanner";
import { CommonModals } from "components/molecules/CommonModals";
import CookieConsent from "components/molecules/CookieConsent";
import Footer from "components/molecules/Footer";
import { Header } from "components/molecules/Header";
import { RegisterBanner } from "components/molecules/RegisterBanner";
import { getUserInfo } from "lib/actions";
import { AppContext } from "lib/contexts";
import { appReducer } from "lib/reducers";
import { AppReducerActionType, UserPremiumType } from "types";
import "react-toastify/dist/ReactToastify.css";
const queryClient = new QueryClient();
export default function LayoutWrapper({
  children
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [fetched, setFetched] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [state, dispatch] = useReducer(appReducer, {
    showPricingTable: false,
    showSubscriptionTable: false,
    gold: 0,
    cookieConsentGiven: true,
    isLoggedIn: false,
    showAuthModal: false,
    userPremiumLevel: UserPremiumType.NONE,
    userFreeGens: 10,
    isOverlayVisible: false,
    username: "",
    email: "",
    referralCode: ""
  });
  const [username, setUsername] = useState<string>("");
  const [unseenChangelogs, setUnseenChangelogs] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      try {
        const {
          user
        } = await getUserInfo();
        if (user) {
          try {
            dispatch({
              type: AppReducerActionType.SET_GOLD,
              payload: {
                ...state,
                gold: user?.gold || 0
              }
            });
            setEmail(user.email || "");
            Sentry.setUser({
              email: user.email
            });
            setUsername(user.username);
            setUnseenChangelogs(user.hasUnseenChangelogs || false);
            dispatch({
              type: AppReducerActionType.SET_LOGGED_IN,
              payload: {
                ...state,
                isLoggedIn: true
              }
            });
            dispatch({
              type: AppReducerActionType.SET_USER_PREMIUM_LEVEL,
              payload: {
                ...state,
                userPremiumLevel: user.subscriptionType ?? UserPremiumType.NONE
              }
            });
            dispatch({
              type: AppReducerActionType.SET_USER_FREE_GENS,
              payload: {
                ...state,
                userFreeGens: 10 - (user.requestsInLastDay ?? 0)
              }
            });
            dispatch({
              type: AppReducerActionType.SET_USERNAME,
              payload: {
                ...state,
                username: user.username
              }
            });
            if (user.referralCode) {
              dispatch({
                type: AppReducerActionType.SET_REFERRAL_CODE,
                payload: {
                  ...state,
                  referralCode: user.referralCode
                }
              });
            }
          } catch (error) {
            console.log(error);
            dispatch({
              type: AppReducerActionType.SET_LOGGED_IN,
              payload: {
                ...state,
                isLoggedIn: false
              }
            });
          } finally {
            setFetched(true);
          }
        }
      } catch (e: any) {
        console.log(e);
      }
      setFetched(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    document.body.style.overflow = state.isOverlayVisible ? "hidden" : "unset";
  }, [state.isOverlayVisible]);
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="LayoutWrapper" data-sentry-source-file="index.tsx">
            <AppContext.Provider value={{
      state,
      dispatch
    }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Header isLoggedIn={state.isLoggedIn} fetched={fetched} userPremiumLevel={state.userPremiumLevel} userEmail={email} username={username} data-sentry-element="Header" data-sentry-source-file="index.tsx" />
                <CookieConsent data-sentry-element="CookieConsent" data-sentry-source-file="index.tsx" />
                {!state.isLoggedIn && fetched && <RegisterBanner />}
                {state.isLoggedIn && fetched && unseenChangelogs && <>
                        <ChangelogBanner />
                    </>}
                {children}
                <Footer data-sentry-element="Footer" data-sentry-source-file="index.tsx" />
                <CommonModals email={email} data-sentry-element="CommonModals" data-sentry-source-file="index.tsx" />
            </AppContext.Provider>
            <StyledToastContainer data-sentry-element="StyledToastContainer" data-sentry-source-file="index.tsx" />
        </QueryClientProvider>;
}