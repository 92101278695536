import { FC, useContext, useEffect } from "react";
import jsCookie from "js-cookie";
import { ButtonType } from "components/atoms/Button/types";
import { AppContext } from "lib/contexts";
import { AppReducerActionType } from "types";
import { Span, Text } from "utils/styling/typography.styled";
import { ConsentButton, CookieBanner } from "./CookieConsent.styled";
export const CookieConsent: FC = () => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  useEffect(() => {
    const consent = jsCookie.get("cookieConsent");
    if (!consent) {
      dispatch({
        type: AppReducerActionType.SET_COOKIE_CONSENT,
        payload: {
          ...state,
          cookieConsentGiven: false
        }
      });
      return;
    }
    if (consent !== "given" && state.cookieConsentGiven) {
      dispatch({
        type: AppReducerActionType.SET_COOKIE_CONSENT,
        payload: {
          ...state,
          cookieConsentGiven: true
        }
      });
    }
    if (consent === "given" && !state.cookieConsentGiven) {
      dispatch({
        type: AppReducerActionType.SET_COOKIE_CONSENT,
        payload: {
          ...state,
          cookieConsentGiven: true
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleConsent = () => {
    jsCookie.set("cookieConsent", "given", {
      expires: 365,
      path: "/",
      secure: true,
      sameSite: "Lax"
    });
    dispatch({
      type: AppReducerActionType.SET_COOKIE_CONSENT,
      payload: {
        ...state,
        cookieConsentGiven: true
      }
    });
  };
  if (state.cookieConsentGiven) {
    return null;
  }
  return <CookieBanner data-sentry-element="CookieBanner" data-sentry-component="CookieConsent" data-sentry-source-file="index.tsx">
            <Text data-sentry-element="Text" data-sentry-source-file="index.tsx">
                This website uses cookies to ensure you get the best experience
                on our website. No advertising cookies are used.
            </Text>
            <ConsentButton variant={ButtonType.SUCCESS} onClick={handleConsent} data-sentry-element="ConsentButton" data-sentry-source-file="index.tsx">
                <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">I Understand</Span>
            </ConsentButton>
        </CookieBanner>;
};
export default CookieConsent;