import styled from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";
import { Small } from "utils/styling/typography.styled";

export const DropdownContainer = styled.div`
    position: relative;
`;

export const NewLabel = styled(Small)`
    vertical-align: top;
    font-size: 0.5rem !important;
    color: ${colourScheme.alert};

    ${device.xl} {
        font-size: 0.75rem !important;
    }
`;

export const PopoverItem = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${colourScheme.white};
    cursor: pointer;
    transition: transform 0.1s ease-in-out;

    & > span {
        white-space: nowrap;
    }

    &:hover {
        transform: scale(1.03);
    }
`;
