var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"chargen-fe@bd282065afec8ccdc210f0dd4c674f4d06f97f90"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/react";

const env = process.env.NODE_ENV;

const release = process.env.NEXT_PUBLIC_SENTRY_RELEASE;

Sentry.init({
    environment: env,
    dsn: "https://aec796a5cae306752ef8517cdb44987d@sentry.vorontsov.co.uk/1",
    release: release || "",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [
                "localhost",
                /api\.char-gen\.com/,
                "https://cognito-idp.eu-west-2.amazonaws.com",
            ],
            networkRequestHeaders: ["X-Custom-Header"],
            networkResponseHeaders: ["X-Custom-Header"],
        }),
        Sentry.feedbackIntegration({
            // Additional SDK configuration goes in here, for example:
            colorScheme: "dark",
            showBranding: false,
            showName: false,
            triggerLabel: "Feedback",
            submitButtonLabel: "Send Feedback",
            formTitle: "Send Feedback",
            successMessageText: "Thank you for your feedback!",
            enableScreenshot: false,
        }),
    ],
    enabled: true,
    tracePropagationTargets: ["localhost", /api\.char-gen\.com/],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
});
