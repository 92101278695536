export const colourScheme = {
    fullWhite: "#FFFFFF",
    white: "#F4F5F6",

    primaryDark: "#161727",
    primary: "#1F2136",
    primaryLight: "#2D2F4E",

    secondaryLight: "#99BBE5",
    secondary: "#6699CC",
    secondaryDark: "#578FC7",

    successLight: "#72D7B2",
    success: "#3ABB8E",
    successDark: "#309C76",

    alertLight: "#ECD8A3",
    alert: "#E4B363",
    alertDark: "#E0AA52",

    errorLight: "#F59C9A",
    error: "#EF6461",
    errorDark: "#EE5A58",

    greyLight: "#C6C6C7",
    grey: "#9B9C9D",
    greyDark: "#2D3748",

    darkGradientStart: "#1E1E2F",
    darkGradientEnd: "#121213",
    secondaryGradientStart: "#2C3E50",
    secondaryGradientEnd: "#4CA1AF",
};

export const hexToRgba = (hex: string, alpha: number): string => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
