import Image from "next/image";
import { styled } from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";
import { Span } from "utils/styling/typography.styled";

export const CounterContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
`;

export const StyledImage = styled(Image)`
    display: grid;
    align-items: center;
    box-sizing: border-box;
`;

export const DynamicNumber = styled(Span)`
    font-size: 1rem !important;
    ${device.lg} {
        font-size: 1.5rem !important;
    }
    color: ${colourScheme.white};
    align-items: center;
    box-sizing: border-box;
    display: grid;
`;
