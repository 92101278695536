import Award from "./award-icon.svg";
import BackgroundIcon from "./background-icon.svg";
import CheckIcon from "./check.svg";
import ChevronDown from "./chevron-down.svg";
import ChevronLeft from "./chevron-left.svg";
import ChevronRight from "./chevron-right.svg";
import ChevronUp from "./chevron-up.svg";
import Cross from "./cross.svg";
import DeleteIcon from "./delete.svg";
import Download from "./download.svg";
import Edit from "./edit.svg";
import EllipsisVertical from "./ellipsis-vertical.svg";
import ExpandIcon from "./expand-icon.svg";
import FaceRecognition from "./face-recognition.svg";
import FirstPlaceIcon from "./first-place-icon.svg";
import HeartFilled from "./heart-filled.svg";
import HeartOutline from "./heart-outline.svg";
import InpaintIcon from "./inpaint-icon.svg";
import Menu from "./menu.svg";
import Notification from "./notification.svg";
import PresetIcon from "./preset.svg";
import QuestionIcon from "./question-circle.svg";
import SecondPlaceIcon from "./second-place-icon.svg";
import ShareIcon from "./share.svg";
import StarIcon from "./star.svg";
import SwapIcon from "./swap-icon.svg";
import ThirdPlaceIcon from "./third-place-icon.svg";
import TrophyIcon from "./trophy.svg";
export { Award, BackgroundIcon, CheckIcon, ChevronDown, ChevronLeft, ChevronRight, ChevronUp, Cross, DeleteIcon, Download, Edit, EllipsisVertical, ExpandIcon, FaceRecognition, FirstPlaceIcon, HeartFilled, HeartOutline, InpaintIcon, Menu, Notification, PresetIcon, QuestionIcon, SecondPlaceIcon, ShareIcon, StarIcon, SwapIcon, ThirdPlaceIcon, TrophyIcon };