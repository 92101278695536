import { styled } from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";

export const RegisterBannerContainer = styled.div`
    ${device.lg} {
        padding: 1rem;
    }
    position: absolute;
    top: 5rem;
    width: 100%;
    padding: 1rem 0.25rem;
    background-color: ${colourScheme.successDark};
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 0 0 2px ${colourScheme.primaryDark};
`;
