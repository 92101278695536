import { FC } from "react";
import { Small } from "utils/styling/typography.styled";
import Discord from "./discord.svg";
import Facebook from "./facebook.svg";
import { FooterContainer, FooterLink, FooterLinks, Icon, SocialMediaLinks, TradingName } from "./Footer.styled";
import Instagram from "./instagram.svg";
import Twitter from "./twitter.svg";

// Footer component
export const Footer: FC = () => {
  return <FooterContainer data-sentry-element="FooterContainer" data-sentry-component="Footer" data-sentry-source-file="index.tsx">
            <FooterLinks data-sentry-element="FooterLinks" data-sentry-source-file="index.tsx">
                <FooterLink href="/about" data-sentry-element="FooterLink" data-sentry-source-file="index.tsx">About</FooterLink>
                <FooterLink href="/blogs" data-sentry-element="FooterLink" data-sentry-source-file="index.tsx">Blogs</FooterLink>
                <FooterLink href="/gallery" data-sentry-element="FooterLink" data-sentry-source-file="index.tsx">Gallery</FooterLink>
                <FooterLink href="/leaderboards" data-sentry-element="FooterLink" data-sentry-source-file="index.tsx">Leaderboards</FooterLink>
                <FooterLink href="/competitions" data-sentry-element="FooterLink" data-sentry-source-file="index.tsx">Competitions</FooterLink>
                <FooterLink href="/pricing" data-sentry-element="FooterLink" data-sentry-source-file="index.tsx">Pricing</FooterLink>
                <FooterLink href="/terms-and-conditions" rel="noopener noreferrer" data-sentry-element="FooterLink" data-sentry-source-file="index.tsx">
                    Terms & Conditions
                </FooterLink>
                <FooterLink href="/privacy" rel="noopener noreferrer" data-sentry-element="FooterLink" data-sentry-source-file="index.tsx">
                    Privacy Policy
                </FooterLink>
            </FooterLinks>
            <SocialMediaLinks data-sentry-element="SocialMediaLinks" data-sentry-source-file="index.tsx">
                <FooterLink href="https://discord.gg/4bCmfqRwqu" target="_blank" rel="noopener noreferrer" aria-label="Visit the CharGen Discord" data-sentry-element="FooterLink" data-sentry-source-file="index.tsx">
                    <Icon priority src={Discord} alt="DiscordIcon" data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
                </FooterLink>
                <FooterLink href="https://www.facebook.com/chargenofficial" target="_blank" rel="noopener noreferrer" aria-label="Visit the CharGen Facebook" data-sentry-element="FooterLink" data-sentry-source-file="index.tsx">
                    <Icon priority src={Facebook} alt="FacebookIcon" data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
                </FooterLink>
                <FooterLink href="https://twitter.com/char_genAI" target="_blank" rel="noopener noreferrer" aria-label="Visit the CharGen Twitter" data-sentry-element="FooterLink" data-sentry-source-file="index.tsx">
                    <Icon priority src={Twitter} alt="TwitterIcon" data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
                </FooterLink>
                <FooterLink href="https://www.instagram.com/chargen_ai/" target="_blank" rel="noopener noreferrer" aria-label="Visit the CharGen Instagram" data-sentry-element="FooterLink" data-sentry-source-file="index.tsx">
                    <Icon priority src={Instagram} alt="InstagramIcon" data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
                </FooterLink>
            </SocialMediaLinks>
            <Small data-sentry-element="Small" data-sentry-source-file="index.tsx">Made with 💖 by the CharGen team</Small>
            <TradingName data-sentry-element="TradingName" data-sentry-source-file="index.tsx">
                Trading as: VORONTSOV CONSULTING SERVICES LTD
            </TradingName>
        </FooterContainer>;
};
export default Footer;