import { FC } from "react";
import * as Styled from "./Payments.styled";
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}
type PaymentsProps = {
  email: string;
};
export const Payments: FC<PaymentsProps> = ({
  email
}) => {
  return <Styled.PaymentsContainer data-sentry-element="unknown" data-sentry-component="Payments" data-sentry-source-file="index.tsx">
            <stripe-pricing-table pricing-table-id="prctbl_1Pk2okFXwCu4IX7BP4jduJ90" publishable-key="pk_live_51NzEpIFXwCu4IX7BkiHCtXF18ZTYj2oA9htM0EQ96LtWLUTkY9z0PpY8Sgs81L0QwX2iG49c2uSHACiJeEd7YjZo00efWR6yqE" customer-email={email} data-sentry-element="stripe-pricing-table" data-sentry-source-file="index.tsx" />
        </Styled.PaymentsContainer>;
};
export const Subscriptions: FC<PaymentsProps> = ({
  email
}) => {
  return <Styled.PaymentsContainer data-sentry-element="unknown" data-sentry-component="Subscriptions" data-sentry-source-file="index.tsx">
            <stripe-pricing-table pricing-table-id="prctbl_1Pk2iHFXwCu4IX7BDrnrfb3J" publishable-key="pk_live_51NzEpIFXwCu4IX7BkiHCtXF18ZTYj2oA9htM0EQ96LtWLUTkY9z0PpY8Sgs81L0QwX2iG49c2uSHACiJeEd7YjZo00efWR6yqE" customer-email={email} data-sentry-element="stripe-pricing-table" data-sentry-source-file="index.tsx" />
        </Styled.PaymentsContainer>;
};