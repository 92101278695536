import styled from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";
import { Small } from "utils/styling/typography.styled";

export const StyledNav = styled.nav`
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    ${device.lg} {
        flex-direction: row;
    }
    ${device.xl} {
        gap: 2rem;
    }

    & a {
        text-decoration: none;
        color: ${colourScheme.white};
        font-size: 1.25rem;
        text-align: center;
        display: flex;
        align-items: center;

        &:hover {
            color: #ddd;
        }
    }
`;

export const Dropdown = styled.div`
    position: relative;
    display: inline-block;
    display: none;

    ${device.lg} {
        display: block;
    }
`;

export const DropdownContent = styled.div`
    display: none;
    position: absolute;
    background-color: ${colourScheme.primaryDark};
    width: auto;
    border-radius: 0 0 0.5rem 0.5rem;
    z-index: 50;
    padding: 0.5rem 0.25rem;

    ${Dropdown}:hover & {
        display: block;
    }

    > button,
    > a {
        color: ${colourScheme.fullWhite};
        padding: 1rem 1rem;
        text-decoration: none;
        display: flex;
        align-items: center;
        text-align: left;
        background: none;
        border: none;
        cursor: pointer;

        &:hover {
            color: ${colourScheme.secondary};
        }
    }
`;
export const DropdownTrigger = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    & span {
        margin-right: 0.5rem;
    }

    & img {
        width: 1rem;
        height: 1rem;
    }
`;

export const GenerateLinks = styled.div`
    display: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    ${device.lg} {
        display: none;
    }
`;

export const NewLabel = styled(Small)`
    font-size: 0.5rem !important;
    color: ${colourScheme.alert};
    align-self: flex-start;

    ${device.xl} {
        font-size: 0.75rem !important;
    }
`;
