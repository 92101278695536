import { type FC, useContext } from "react";
import { AppContext } from "lib/contexts";
import { AppReducerActionType } from "types";
import * as Styled from "./GemCounter.styled";
import Token from "./TokenIcon.png";
type GemCounterProps = {
  gold: number;
};
export const GemCounter: FC<GemCounterProps> = ({
  gold
}) => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  const handlePricingClick = () => {
    dispatch({
      type: AppReducerActionType.SHOW_PRICING_TABLE,
      payload: {
        ...state,
        showPricingTable: !state.showPricingTable
      }
    });
  };
  return <Styled.CounterContainer onClick={handlePricingClick} data-sentry-element="unknown" data-sentry-component="GemCounter" data-sentry-source-file="index.tsx">
            <Styled.StyledImage height={40} width={40} alt="gold" src={Token} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
            <Styled.DynamicNumber data-sentry-element="unknown" data-sentry-source-file="index.tsx">{gold}</Styled.DynamicNumber>
        </Styled.CounterContainer>;
};