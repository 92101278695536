"use client";

import { FC, useContext } from "react";
import { Modal } from "components/atoms/Modal";
import { Payments, Subscriptions } from "components/atoms/Payments";
import { AuthModal } from "components/molecules/AuthModal";
import { AppContext } from "lib/contexts";
import { AppReducerActionType } from "types";
type CommonModalsProps = {
  email: string;
};
export const CommonModals: FC<CommonModalsProps> = ({
  email = ""
}) => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  const handleBackdropClick = () => {
    dispatch({
      type: AppReducerActionType.SHOW_PRICING_TABLE,
      payload: {
        ...state,
        showPricingTable: false
      }
    });
    dispatch({
      type: AppReducerActionType.SHOW_SUBSCRIPTION_TABLE,
      payload: {
        ...state,
        showSubscriptionTable: false
      }
    });
    dispatch({
      type: AppReducerActionType.SHOW_AUTH_MODAL,
      payload: {
        ...state,
        showAuthModal: false
      }
    });
  };
  return <>
            <Modal open={state.showPricingTable} handleBackdropClick={handleBackdropClick} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
                <Payments email={email} data-sentry-element="Payments" data-sentry-source-file="index.tsx" />
            </Modal>
            <Modal open={state.showSubscriptionTable} handleBackdropClick={handleBackdropClick} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
                <Subscriptions email={email} data-sentry-element="Subscriptions" data-sentry-source-file="index.tsx" />
            </Modal>
            <AuthModal isOpen={state.showAuthModal} handleBackdropClick={handleBackdropClick} data-sentry-element="AuthModal" data-sentry-source-file="index.tsx" />
        </>;
};