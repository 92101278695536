import { styled } from "styled-components";

import { Button } from "components/atoms/Button";
import { colourScheme } from "utils/styling/colours";

export const CookieBanner = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${colourScheme.white};
    color: ${colourScheme.primaryDark};
    height: auto;
    padding: 1.25rem;
    text-align: center;
    z-index: 1000;
    box-shadow: 0 -0.125rem 0.5rem rgba(0, 0, 0, 0.1);
`;

export const ConsentButton = styled(Button)`
    padding: 0.625rem 1.25rem;
    margin-top: 0.625rem;
    border: none;
    cursor: pointer;
`;
