import { FC, forwardRef, useContext, useEffect } from "react";
import Image from "next/image";
import { Cross, Menu } from "components/atoms/Icon";
import { AppContext } from "lib/contexts";
import { useDetectClickOut } from "lib/hooks";
import { AppReducerActionType } from "types";
import * as Styled from "./Sidebar.styled";
type SidebarProps = {
  children: React.ReactNode;
};
export const SidebarContent = forwardRef<any, any>((props, ref) => {
  return <Styled.SidebarContainer ref={ref} $active={props.show}>
            <div>{props.children}</div>
        </Styled.SidebarContainer>;
});
SidebarContent.displayName = "SidebarContent";
export const Sidebar: FC<SidebarProps> = ({
  children
}) => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  const {
    show,
    nodeRef,
    triggerRef
  } = useDetectClickOut(false);
  useEffect(() => {
    dispatch({
      type: AppReducerActionType.TOGGLE_OVERLAY_VISIBILITY,
      payload: {
        ...state,
        isOverlayVisible: show
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  return <>
            <Styled.SidebarToggle ref={triggerRef} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                {show ? <Image priority src={Cross} alt="Close Icon" /> : <Image priority src={Menu} alt="Menu Icon" />}
            </Styled.SidebarToggle>
            <SidebarContent show={show} ref={nodeRef} data-sentry-element="SidebarContent" data-sentry-source-file="index.tsx">
                {children}
            </SidebarContent>
        </>;
};
Sidebar.displayName = "Sidebar";