import { styled } from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";

export const ModalOverlay = styled.div<{ $open: boolean }>`
    display: ${({ $open }) => ($open ? "flex" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    backdrop-filter: blur(0.25rem);
    background-color: ${colourScheme.primaryDark}99;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    align-items: center;
    justify-content: center;
    ${device.lg} {
        max-height: 90vh;
    }
`;

export const ModalContainer = styled.div`
    background-color: ${colourScheme.primaryLight};
    flex: 20;
    padding: 0.25rem;
    max-width: fit-content;
    border-radius: 0.5rem;
    overscroll-behavior: none;
`;

export const ModalHeader = styled.div`
    height: 2.5rem;
    display: flex;
    justify-content: end;
`;

export const ModalIcon = styled.div`
    height: 2.5rem;
    width: 2.5rem;

    &:hover {
        cursor: pointer;
    }

    & svg,
    img {
        height: 100%;
        width: 100%;
    }
`;

export const ControlIconWrapper = styled.div`
    position: relative;
    z-index: 10000;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    flex: 1;

    &:hover {
        transform: scale(1.1);
    }

    ${device.md} {
        display: flex;
    }
`;
