import styled from "styled-components";

import { Button } from "components/atoms/Button";
import { colourScheme } from "utils/styling/colours";

export const StyledButton = styled(Button)`
    background-color: ${colourScheme.primaryDark};
    color: ${colourScheme.white};

    &:disabled {
        cursor: default;
        opacity: 0.8;
    }

    &:hover:enabled,
    &:focus:enabled {
        background-color: ${colourScheme.primaryDark};
        border-radius: 0.25rem;
        cursor: pointer;
    }
`;
