import { FC, useCallback, useContext } from "react";
import { Auth } from "@aws-amplify/auth";
import * as Sentry from "@sentry/react";
import Link from "next/link";
import { Button } from "components/atoms/Button";
import { ButtonType } from "components/atoms/Button/types";
import { AppContext } from "lib/contexts";
import { AppReducerActionType } from "types";
import { Span } from "utils/styling/typography.styled";
import { StyledButton } from "./AuthButton.styled";
export const AuthButton: FC = () => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  const handleRegister = useCallback(() => {
    dispatch({
      type: AppReducerActionType.SHOW_AUTH_MODAL,
      payload: {
        ...state,
        showAuthModal: true
      }
    });
  }, [dispatch, state]);
  return <Button onClick={handleRegister} variant={ButtonType.PRIMARY} data-sentry-element="Button" data-sentry-component="AuthButton" data-sentry-source-file="index.tsx">
            <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">{"Sign Up | Log in"}</Span>
        </Button>;
};
export const SignOutButton: FC = () => {
  const handleSignOut = useCallback(async () => {
    setTimeout(async () => {
      await Auth.signOut();
      Sentry.setUser(null);
      window.location.replace(window.location.origin);
    }, 500);
  }, []);
  return <StyledButton onClick={handleSignOut} data-sentry-element="StyledButton" data-sentry-component="SignOutButton" data-sentry-source-file="index.tsx">
            <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Sign Out</Span>
        </StyledButton>;
};
export const ProfileButton: FC = () => {
  return <StyledButton data-sentry-element="StyledButton" data-sentry-component="ProfileButton" data-sentry-source-file="index.tsx">
            <Link href={"/profile"} data-sentry-element="Link" data-sentry-source-file="index.tsx">
                <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Profile</Span>
            </Link>
        </StyledButton>;
};