import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { captureException } from "@sentry/react";
import { Button, InlineButton } from "components/atoms/Button";
import { ButtonType } from "components/atoms/Button/types";
import { Input } from "components/atoms/Input";
import { Modal } from "components/atoms/Modal";
import { ReactTooltipStyled } from "components/atoms/Tooltip/Tooltip.styled";
import { AppContext } from "lib/contexts";
import { Small, Span, SubTitle, Text } from "utils/styling/typography.styled";
import * as Styled from "./AuthModal.styled";
type AuthModalProps = {
  isOpen: boolean;
  handleBackdropClick: () => void;
};
const pattern = new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"\-!@#%&/,><':;|_~`+=]).{8,}/);
enum AuthStage {
  LOG_IN = 1,
  SIGN_UP,
  CONFIRM_SIGN_UP,
  FORGOT_PASSWORD,
  CONFIRM_FORGOT_PASSWORD,
}
const PasswordToolTipInfo = () => {
  return <ul data-sentry-component="PasswordToolTipInfo" data-sentry-source-file="index.tsx">
            <li>
                <Small data-sentry-element="Small" data-sentry-source-file="index.tsx">At least one lowercase letter</Small>
            </li>
            <li>
                <Small data-sentry-element="Small" data-sentry-source-file="index.tsx">At least one uppercase letter</Small>
            </li>
            <li>
                <Small data-sentry-element="Small" data-sentry-source-file="index.tsx">At least one number</Small>
            </li>
            <li>
                <Small data-sentry-element="Small" data-sentry-source-file="index.tsx">At least one symbol</Small>
            </li>
            <li>
                <Small data-sentry-element="Small" data-sentry-source-file="index.tsx">At least 8 characters or more</Small>
            </li>
        </ul>;
};
const ReferralToolTipInfo = () => {
  return <>
            <Small data-sentry-element="Small" data-sentry-source-file="index.tsx">Have a referral code from a friend? Enter it here!</Small>
            <br />
            <Small data-sentry-element="Small" data-sentry-source-file="index.tsx">Earn 250 gold on your first qualifying purchase.</Small>
            <br />
            <Small data-sentry-element="Small" data-sentry-source-file="index.tsx">
                Qualifying purchases include any gold amount or non-Basic
                subscription.
            </Small>
        </>;
};
export const AuthModal: FC<AuthModalProps> = props => {
  const {
    state
  } = useContext(AppContext);
  const [authStage, setAuthStage] = useState<AuthStage>(AuthStage.LOG_IN);
  const [confirmCode, setConfirmCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [marketingConsent, setMarketingConsent] = useState<string>("true");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [referralCode, setReferralCode] = useState<string>(state.referralCode || "");

  // Reset values on stage change except for email
  useEffect(() => {
    setPassword("");
    setConfirmPassword("");
    setConfirmCode("");
  }, [authStage]);
  useEffect(() => {
    if (state.referralCode) {
      setReferralCode(state.referralCode);
    }
  }, [state.referralCode]);
  const handleSignIn = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setError("");
    try {
      await Auth.signIn(email, password);
      const auth = await Auth.currentSession();
      if (auth) {
        window.location.reload();
      }
    } catch (e: any) {
      captureException("error signing in", e.message);
      setError(e.message);
      if (e.message === "User is not confirmed.") {
        await Auth.resendSignUp(email);
        setAuthStage(AuthStage.CONFIRM_SIGN_UP);
      }
    } finally {
      setLoading(false);
    }
  }, [email, password]);
  const handleMarketingConsent = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const checkedStr = checked ? "true" : "false";
    setMarketingConsent(checkedStr);
  }, []);
  const handleSignUp = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setError("");
    if (password !== confirmPassword) {
      setLoading(false);
      return;
    }
    try {
      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
          "custom:marketingConsent": marketingConsent,
          "custom:referralCode": referralCode
        },
        autoSignIn: {
          enabled: true
        }
      });
      setAuthStage(AuthStage.CONFIRM_SIGN_UP);
    } catch (e: any) {
      captureException("error signing up", e.message);
      if (e.message === "PreSignUp failed with error Invalid email domain.") {
        setError("Invalid email domain");
      } else {
        setError(e.message);
      }
    } finally {
      setLoading(false);
    }
  }, [password, confirmPassword, email, marketingConsent, referralCode]);
  const handleConfirmSignUp = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setError("");
    try {
      await Auth.confirmSignUp(email, confirmCode.trim());
      setTimeout(async () => {
        const auth = await Auth.currentAuthenticatedUser();
        if (auth) {
          window.location.reload();
        }
      }, 1000);
    } catch (e: any) {
      captureException("error confirming sign up", e.message);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, [confirmCode, email]);
  const handlePassword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const valid = pattern.test(value);
    if (!valid) {
      e.target.setCustomValidity("Invalid password format");
      setCanSubmit(false);
    } else {
      e.target.setCustomValidity("");
      setCanSubmit(true);
    }
    setPassword(e.target.value);
  }, []);
  const handleConfirmPassword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const valid = pattern.test(value);
    if (!valid) {
      e.target.setCustomValidity("Invalid password format");
      setCanSubmit(false);
    } else {
      e.target.setCustomValidity("");
      setCanSubmit(true);
    }
    setConfirmPassword(e.target.value);
  }, []);
  const handleForgotPassword = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setError("");
    try {
      await Auth.forgotPassword(email);
      setAuthStage(AuthStage.CONFIRM_FORGOT_PASSWORD);
    } catch (e: any) {
      captureException("error resetting password", e.message);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, [email]);
  const handlePasswordReset = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setError("");
    if (password !== confirmPassword) {
      setLoading(false);
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(email, confirmCode, password);
      setAuthStage(AuthStage.LOG_IN);
    } catch (e: any) {
      captureException("error signing up", e.message);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, [confirmCode, confirmPassword, email, password]);
  const handleRequestNewCode = useCallback(async () => {
    await Auth.resendSignUp(email);
  }, [email]);
  return <Modal open={props.isOpen} handleBackdropClick={props.handleBackdropClick} disableBackdropClick data-sentry-element="Modal" data-sentry-component="AuthModal" data-sentry-source-file="index.tsx">
            <Styled.AuthModalContainer data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                {(() => {
        switch (authStage) {
          case AuthStage.LOG_IN:
            return <>
                                    <SubTitle>
                                        Bring your character to life
                                    </SubTitle>
                                    <hr />
                                    {error && <p>{error}</p>}
                                    <Styled.AuthForm onSubmit={handleSignIn}>
                                        <Input name="email" id="email" inputMode="email" type="email" placeholder="Email" autoComplete="email" aria-placeholder="Email" required aria-required value={email} onChange={e => setEmail(e.target.value)} />
                                        <Styled.InlineInput>
                                            <Input name="password" id="password" data-sentry-mask inputMode="text" type="password" placeholder="Password" aria-placeholder="Password" required aria-required value={password} onChange={e => setPassword(e.target.value)} password />
                                            <InlineButton style={{
                    opacity: 0.5
                  }} onClick={() => setAuthStage(AuthStage.FORGOT_PASSWORD)}>
                                                <Span>Forgot password?</Span>
                                            </InlineButton>
                                        </Styled.InlineInput>
                                        <Button disabled={loading} type="submit" variant={ButtonType.SUCCESS}>
                                            <Span>Log in</Span>
                                        </Button>
                                    </Styled.AuthForm>
                                    <Styled.TextButton onClick={() => setAuthStage(AuthStage.SIGN_UP)}>
                                        Don&apos;t have an account?
                                    </Styled.TextButton>
                                </>;
          case AuthStage.SIGN_UP:
            return <>
                                    <SubTitle>
                                        Bring your character to life
                                    </SubTitle>
                                    <hr />
                                    {error && <p>{error}</p>}
                                    <Styled.AuthForm onSubmit={handleSignUp}>
                                        <Input name="email" id="email" inputMode="email" type="email" placeholder="Email" aria-placeholder="Email" required={true} aria-required autoComplete="email" value={email} onChange={e => setEmail(e.target.value)} />
                                        <Input name="_password" id="password" data-sentry-mask inputMode="text" type="password" placeholder="Password" aria-placeholder="Password" required aria-required value={password} onChange={handlePassword} minLength={8} title="Must contain at least one number, one uppercase, one lowercase letter and one special character, and at least 8 or more characters" data-tooltip-id="passwordTip" aria-invalid={password !== confirmPassword} password />
                                        <Input name="confirm_password" id="confirm_password" data-sentry-mask inputMode="text" type="password" placeholder="Confirm Password" aria-placeholder="Confirm Password" required aria-required value={confirmPassword} onChange={handleConfirmPassword} minLength={8} title="Must contain at least one number, one uppercase, one lowercase letter and one special character, and at least 8 or more characters" data-tooltip-id="passwordConfirmTip" aria-invalid={password !== confirmPassword} password />
                                        <Styled.CheckboxContainer>
                                            <Styled.Checkbox>
                                                <input id="marketingConsentCheckbox" type="checkbox" checked={marketingConsent === "true"} onChange={handleMarketingConsent} />
                                                <span className="checkmark"></span>
                                            </Styled.Checkbox>
                                            <Styled.Label htmlFor="marketingConsentCheckbox">
                                                I agree to receive updates and
                                                promotional emails from CharGen.
                                                Please untick this box if you do
                                                not consent.
                                            </Styled.Label>
                                        </Styled.CheckboxContainer>
                                        <ReactTooltipStyled openOnClick clickable closeOnEsc closeOnScroll id="referralTip" place="top">
                                            <ReferralToolTipInfo />
                                        </ReactTooltipStyled>
                                        <Input name="referral_code" id="referral_code" inputMode="text" type="referral_code" placeholder="Referral Code" aria-placeholder="referral_code" required={false} aria-required value={referralCode} data-tooltip-id="referralTip" onChange={e => setReferralCode(e.target.value)} />
                                        <Button disabled={loading || !canSubmit} type="submit" variant={ButtonType.SUCCESS}>
                                            <Span>Sign up</Span>
                                        </Button>
                                    </Styled.AuthForm>
                                    <Styled.TextButton onClick={() => setAuthStage(AuthStage.LOG_IN)}>
                                        Already registered?
                                    </Styled.TextButton>
                                </>;
          case AuthStage.CONFIRM_SIGN_UP:
            return <>
                                    <SubTitle>Confirm your email</SubTitle>
                                    <Text>
                                        A code has been sent to your email.
                                        <br />
                                        Please enter the code to confirm your
                                        email.
                                    </Text>
                                    <hr />
                                    {error && <p>{error}</p>}
                                    <Styled.AuthForm onSubmit={handleConfirmSignUp}>
                                        <Input name="code" id="code" inputMode="text" type="text" placeholder="Code" aria-placeholder="code" required={true} aria-required value={confirmCode} onChange={e => setConfirmCode(e.target.value)} />
                                        <Button disabled={loading} onClick={handleRequestNewCode} variant={ButtonType.PRIMARY} type="button">
                                            <Span>Request new code</Span>
                                        </Button>
                                        <Button disabled={loading} type="submit" variant={ButtonType.SUCCESS}>
                                            <Span>Confirm</Span>
                                        </Button>
                                    </Styled.AuthForm>
                                </>;
          case AuthStage.FORGOT_PASSWORD:
            return <>
                                    <SubTitle>Forgot your password?</SubTitle>
                                    <Text>
                                        Enter your Email below and we will send
                                        a<br />
                                        message to reset your password
                                    </Text>
                                    <hr />
                                    {error && <p>{error}</p>}
                                    <Styled.AuthForm onSubmit={handleForgotPassword}>
                                        <Input name="email" id="email" inputMode="email" type="email" placeholder="Email" aria-placeholder="Email" required={true} aria-required autoComplete="email" value={email} onChange={e => setEmail(e.target.value)} />
                                        <Button disabled={loading} type="submit" variant={ButtonType.SUCCESS}>
                                            <Span>Reset my password</Span>
                                        </Button>
                                    </Styled.AuthForm>
                                    <Styled.TextButton onClick={() => setAuthStage(AuthStage.LOG_IN)}>
                                        Back
                                    </Styled.TextButton>
                                </>;
          case AuthStage.CONFIRM_FORGOT_PASSWORD:
            return <>
                                    <Text>
                                        We have sent a password reset code to
                                        your email.
                                        <br />
                                        Please enter the code to reset your
                                        password.
                                    </Text>
                                    <hr />
                                    {error && <p>{error}</p>}
                                    <Styled.AuthForm onSubmit={handlePasswordReset}>
                                        <Input name="code" id="code" inputMode="text" type="text" placeholder="Code" aria-placeholder="code" required={true} aria-required value={confirmCode} onChange={e => setConfirmCode(e.target.value)} />
                                        <ReactTooltipStyled openOnClick clickable id="passwordTip" place="bottom">
                                            <PasswordToolTipInfo />
                                        </ReactTooltipStyled>
                                        <Input name="_password" id="password" data-sentry-mask inputMode="text" type="password" placeholder="Password" aria-placeholder="Password" required aria-required value={password} onChange={handlePassword} minLength={8} title="Must contain at least one number, one uppercase, one lowercase letter and one special character, and at least 8 or more characters" data-tooltip-id="passwordTip" aria-invalid={password !== confirmPassword} password />
                                        <ReactTooltipStyled openOnClick clickable id="passwordConfirmTip" place="bottom">
                                            <PasswordToolTipInfo />
                                        </ReactTooltipStyled>
                                        <Input name="confirm_password" id="confirm_password" data-sentry-mask inputMode="text" type="password" placeholder="Confirm Password" aria-placeholder="Confirm Password" required aria-required value={confirmPassword} onChange={handleConfirmPassword} minLength={8} title="Must contain at least one number, one uppercase, one lowercase letter and one special character, and at least 8 or more characters" data-tooltip-id="passwordConfirmTip" aria-invalid={password !== confirmPassword} password />
                                        <Button disabled={loading || !canSubmit} type="submit" variant={ButtonType.SUCCESS}>
                                            <Span>Change Password</Span>
                                        </Button>
                                    </Styled.AuthForm>
                                </>;
        }
      })()}
            </Styled.AuthModalContainer>
            <ReactTooltipStyled globalCloseEvents={{
      clickOutsideAnchor: true,
      escape: true,
      scroll: true
    }} closeEvents={{
      blur: true,
      click: true,
      dblclick: true
    }} openEvents={{
      click: true,
      focus: true,
      mouseenter: true
    }} anchorSelect="[name$=_password]" place="bottom" data-sentry-element="ReactTooltipStyled" data-sentry-source-file="index.tsx">
                <PasswordToolTipInfo data-sentry-element="PasswordToolTipInfo" data-sentry-source-file="index.tsx" />
            </ReactTooltipStyled>
        </Modal>;
};