import { styled } from "styled-components";

import { colourScheme } from "utils/styling/colours";
import { Span } from "utils/styling/typography.styled";

export const InputWrapper = styled.div`
    flex-grow: 2;
`;

export const InputContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 0.5rem;
`;

export const Input = styled.input`
    transition: all 0.25s;
    width: 100%;

    background-color: ${colourScheme.primary};
    color: ${colourScheme.white};
    border: 2px solid ${colourScheme.white};
    border-radius: 0.5rem;
    font-size: 1rem;
    min-height: 2rem;
    padding: 0.5rem;
    font-family: inherit;

    &:focus {
        outline: none;
        border-radius: 0.25rem;
        border: 2px solid ${colourScheme.secondary};
    }

    &:user-invalid {
        border: 2px solid ${colourScheme.error};
    }

    &:disabled {
        opacity: 0.5;
    }

    &::file-selector-button {
        transition: all 0.25s;
        border: 1px solid ${colourScheme.white};
        padding: 0.2em 0.4em;
        border-radius: 0.2em;
        background-color: ${colourScheme.primary};
        color: ${colourScheme.white};
        font-family: inherit;

        &:hover {
            background-color: ${colourScheme.primaryLight};
            border-radius: 0.25rem;
            cursor: pointer;
        }
    }
    & > div {
        z-index: 1000;
        color: white;
        border: 1px solid ${colourScheme.white};
    }
`;

export const TextArea = styled.textarea`
    flex-grow: 1;
    width: 100%;
    padding: 0.5rem;
    border: 2px solid ${colourScheme.white};
    border-radius: 0.5rem;
    background-color: ${colourScheme.primary};
    color: ${colourScheme.white};
    outline: none;
    resize: vertical;
    min-height: 3rem;

    transition: all 0.25s;

    font-size: 1rem;
    font-family: inherit;

    &:focus {
        outline: none;
        border-radius: 0.25rem;
        border: 2px solid ${colourScheme.secondary};
    }

    & > div {
        z-index: 1000;
        color: white;
        border: 1px solid ${colourScheme.white};
    }

    &:disabled {
        opacity: 0.5;
    }
`;

export const PasswordToggle = styled.span`
    cursor: pointer;
    color: ${colourScheme.white};
    padding: 0.5rem;
    width: 2.5rem;
    user-select: none;
`;

export const ErrorMessage = styled(Span)`
    display: block;
    position: relative;
    color: ${colourScheme.error};
    line-height: 2rem;
    text-align: right;
`;
