import React, { ReactNode } from "react";
import * as Styled from "./Popover.styled";
import { PopoverPanelVariant } from "./types";
interface PopoverWithChildrenProps {
  children: ReactNode;
  buttonNode: ReactNode;
  position?: "top" | "bottom" | "left" | "right" | "bottomLeft";
  panelVariant?: PopoverPanelVariant;
}
const PopoverWithChildren: React.FC<PopoverWithChildrenProps> = ({
  children,
  buttonNode,
  position = "top",
  panelVariant = PopoverPanelVariant.BASE
}) => {
  const PanelComponent = panelVariant === PopoverPanelVariant.NOTIFICATIONS ? Styled.NotificationsPopoverPanel : Styled.BasePopoverPanel;
  return <Styled.Popover data-sentry-element="unknown" data-sentry-component="PopoverWithChildren" data-sentry-source-file="index.tsx">
            <Styled.PopoverButton data-sentry-element="unknown" data-sentry-source-file="index.tsx">{buttonNode}</Styled.PopoverButton>
            <PanelComponent position={position} data-sentry-element="PanelComponent" data-sentry-source-file="index.tsx">
                <Styled.PopoverContent data-sentry-element="unknown" data-sentry-source-file="index.tsx">{children}</Styled.PopoverContent>
            </PanelComponent>
        </Styled.Popover>;
};
export default PopoverWithChildren;